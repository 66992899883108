// Dependencies
import React from 'react';
import { useEffect } from 'react';
import { toJS } from 'mobx';
import Link from 'next/link';
import { observer } from 'mobx-react';
import { Button } from '@anephenix/ui';

// Components
import Dashboard from '../src/components/dashboard/Dashboard';

// Helpers
import useStores from '../src/helpers/useStores';

// Stores
import errorStore from '../src/stores/errorStore';
import messageStore from '../src/stores/messageStore';
import dashboardsStore from '../src/stores/dashboardsStore';

// Deletes a dashboard from the system
const deleteDashboard = async (id) => {
	try {
		await dashboardsStore.delete(id);
		messageStore.handle('Dashboard deleted');
	} catch (error) {
		console.error(error);
		errorStore.handle('Dashboard deletion failed');
	}
};

// The dashboard list item component
const DashboardItem = ({ id, name, widgets }) => (
	<li key={id} id={`dashboard-${id}`}>
		<div className="dashboard-name-and-actions">
			<Link href={'/dashboards/' + id} role="view">
				{name}
			</Link>
			<div>
				<Link href={'/dashboards/' + id + '/edit'} role="edit">
					Edit
				</Link>
				<Button
					className="button theme-default primary delete"
					role="delete"
					text="Delete"
					onClick={() => {
						deleteDashboard(id);
					}}
				/>
			</div>
		</div>
		<Link href={'/dashboards/' + id}>
			<div className="dashboard-preview">
				<Dashboard
					loading={false}
					error={null}
					widgets={widgets}
					preview={true}
				/>
			</div>
		</Link>{' '}
	</li>
);

// The homepage component
const Home = ({ loading, error, dashboards }) => {
	if (loading) return <div id="homepage">Loading...</div>;
	if (error) return <div id="homepage">Error :(</div>;
	return (
		<div id="homepage">
			<div id="dashboards-list">
				<div id="dashboards-list-heading">
					<h1>Your dashboards</h1>
					<Link
						href="/dashboards/new"
						className="button theme-default green-one"
						id="new-dashboard"
					>
						new dashboard
					</Link>
				</div>
				<ul id="dashboards">{dashboards.map(DashboardItem)}</ul>
			</div>
		</div>
	);
};

// The main page
const HomePage = observer(() => {
	const { dashboardsStore } = useStores();
	const { error, loading, dashboards } = dashboardsStore;

	useEffect(() => {
		(async () => {
			await dashboardsStore.getAll();
			await dashboardsStore.listenForUpdates();
		})();
		return function cleanup() {
			(async () => {
				await dashboardsStore.stopListeningForUpdates();
			})();
		};
	}, []);

	return (
		<Home loading={loading} error={error} dashboards={toJS(dashboards)} />
	);
});

export default HomePage;
